import React, { useEffect } from 'react'
import { Button, Carousel } from 'antd';
import logo from '../../img/logo.png'
import logo2 from '../../img/logoWhite.png'
import main1 from '../../img/1.png'
import main2 from '../../img/2.png'
import main3 from '../../img/3.png'
import main4 from '../../img/4.png'
import mainIcon1 from '../../img/icon1.svg'
import mainIcon2 from '../../img/icon2.svg'
import mainIcon3 from '../../img/icon3.svg'
import mainIcon4 from '../../img/icon8.svg'
import mainIcon5 from '../../img/icon7.svg'
import mainIcon6 from '../../img/icon9.svg'
import mainIcon7 from '../../img/icon4.svg'
import mainIcon8 from '../../img/icon5.svg'
import mainIcon9 from '../../img/icon6.svg'
import mainIcon10 from '../../img/icon10.svg'
import mainIcon11 from '../../img/icon11.svg'
import mainIcon12 from '../../img/icon12.svg'
import mainIcon13 from '../../img/fill.svg'
import erWeiMa from '../../img/erWeiMa.svg'
import './index.less'

export default function Index() {

  const resizeListener = () => {
    // 定义设计图的尺寸 3840
    let designSize = 1920;
    // 获取 html 元素
    let html = document.documentElement;
    // 定义窗口的宽度
    let clientW = html.clientWidth;
    let clienH = html.clientHight;
    // html 的fontsize 大小
    let htmlRem = (clientW * 16) / designSize;
    console.log(html, clientW, clienH, htmlRem);
    html.style.fontSize = htmlRem + 'px';
    // console.log(clientW);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    resizeListener();
  }, [])

  const contentStyle = {
    height: '46.75rem',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  return (
    <div className='home'>
      <header>
        <img src={logo} alt="" />
        <div>
          <Button> <a target="_blank" href="https://org.zjtxpy.cn/">企业登录</a> </Button>
          <Button type="primary"> <a target="_blank" href="https://gov.zjtxpy.cn/">管理登录</a></Button>
        </div>
      </header>
      <Carousel autoplay speed={1000} autoplaySpeed={2000} effect="fade" dotPosition={'bottom'} dots={false}>
        <div>
          <h3 style={contentStyle}>
            <img src='https://zjtxpy.oss-cn-beijing.aliyuncs.com/public/zjtxpy_bannar/bannar1.jpg' alt="" />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img src='https://zjtxpy.oss-cn-beijing.aliyuncs.com/public/zjtxpy_bannar/bannar2.jpg' alt="" />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img src='https://zjtxpy.oss-cn-beijing.aliyuncs.com/public/zjtxpy_bannar/bannar3.jpg' alt="" />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img src='https://zjtxpy.oss-cn-beijing.aliyuncs.com/public/zjtxpy_bannar/bannar4.jpg' alt="" />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img src='https://zjtxpy.oss-cn-beijing.aliyuncs.com/public/zjtxpy_bannar/bannar5.jpg' alt="" />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            <img src='https://zjtxpy.oss-cn-beijing.aliyuncs.com/public/zjtxpy_bannar/bannar6.jpg' alt="" />
          </h3>
        </div>
      </Carousel>
      <div className='bannerBottom'>
        <div>
          <div>
            <p>400万+</p>
            <span>海外优秀人才</span>
          </div>
          <div>
            <p>800万+</p>
            <span>国内硕博人才</span>
          </div>
          <div>
            <p>30万+</p>
            <span>共享客户圈</span>
          </div>
          <div>
            <p>100+</p>
            <span>产品链图谱</span>
          </div>
          <div>
            <p>10000+</p>
            <span>专精特新企业</span>
          </div>
        </div>
        <div className='div2'>
          <div>
            <p>1000+</p>
            <span>专精特新“小巨人”企业</span>
          </div>
          <div>
            <p>100+</p>
            <span>制造业单项冠军企业</span>
          </div>
          <div>
            <p>100+</p>
            <span>主板上市公司</span>
          </div>
          <div>
            <p>50+</p>
            <span>高校、科研院所</span>
          </div>
        </div>
      </div>
      <main>
        <h1>全场景数智赋能  高质量发展利器</h1>
        <div className='div1'>
          <div>
            <p> <img src={mainIcon1} alt="" /> 找政策</p>
            <p>精准匹配推送 政策“聪明”到企</p>
            <p>35余万条政策更新推送，帮企业及时获知政策
              匹配企业所属行业、领域，帮企业精准获知政策
              提供咨询服务，帮企业懂政策、争取政策红利</p>
          </div>
          <img src={main1} alt="" />
        </div>
        <div className='div1'>
          <img src={main2} alt="" />
          <div>
            <p>找商机 <img src={mainIcon2} alt="" /> </p>
            <p>汇集线索红海 商机商脉直通</p>
            <p>60余万招标项目更新推送，帮企业及时触达，增加中标率
              100余条核心产业链链点，帮企业触达上下游22000余万领军同学企业，搭建1万多家大客户集群共享体系，帮企业借力同学企业以“熟”找“熟”
            </p>
          </div>
        </div>
        <div className='div1'>
          <div>
            <p> <img src={mainIcon3} alt="" /> 找人才</p>
            <p>人才集聚高地 打破引才壁垒</p>
            <p>汇集超1000万国内外高层次人才，帮企业多渠道引才
              支持按技术、领域等全量搜索，帮企业形成精准人才画像
              多链路人才触达，帮企业降低引才、用才成本
            </p>
          </div>
          <img src={main3} alt="" />
        </div>
      </main>
      <div className='main2'>
        <div className='div1'>
          <img src={mainIcon4} alt="" />
          <div>
            <p>查专利</p>
            <p>专利前瞻布局 助推技术创新</p>
            <p>
              专利前瞻布局 助推技术创新<br />
              送，帮企业无忧获取、触达<br />
              代理机构按行业、领域推送<br />
              专利情报，帮企业掌握行业<br />
              技术创新动态，沉淀300余<br />
              项高校、科研院所科技成果<br />
              资源，帮企业对接技转资源<br />
            </p>
          </div>
        </div>
        <div className='div1'>
          <img src={mainIcon5} alt="" />
          <div>
            <p>查企业</p>
            <p>速递企业情报 动态监测风险</p>
            <p>
              汇集超1.3亿家优质实体企业，支<br />
              持查询企业全量信息，支持查询<br />
              竞争对手及行业技术创新动态，<br />
              辅助企业研发决策和战略布局，<br />
              实时监测企业严重违法情况、经<br />
              营异常情况，帮企业防患于未然<br />
            </p>
          </div>
        </div>
        <div className='div1'>
          <img src={mainIcon6} alt="" />
          <div>
            <p>马上评</p>
            <p>梯度培育测评〝一企一策”帮促</p>
            <p>
              测评报告随测随出，帮企业明确<br />
              “专精特新”认定的短板弱项，多<br />
              维度量化评估机制，量化企业当<br />
              前面临的制约阻碍，提供“一企<br />
              一策”个性化培育服务，推动企<br />
              业高质量发展
            </p>
          </div>
        </div>
      </div>
      <div className='main3'>
        <h2>技术优势</h2>
        <div className='main31'>
          <div className='div1'>
            <img src={mainIcon7} alt="" />
            <div>
              <p>自主研发 数据赋能</p>
              <p>
                数据自主可控，产品迭代迅速<br />
                以市场需求为导向，实现功能<br />
                配置
              </p>
            </div>
          </div>
          <div className='div1'>
            <img src={mainIcon8} alt="" />
            <div>
              <p>算法灵活 能效提升</p>
              <p>
                自有算法．模型丰富，算力持<br />
                续优化，提升数据处理性能和<br />
                效率
              </p>
            </div>
          </div>
          <div className='div1'>
            <img src={mainIcon9} alt="" />
            <div>
              <p>AI引擎 精准画像</p>
              <p>
                {/* 1000+标签、全量数据库，为精准画<br />
                像提供数据支撑、Al采集、分析、处<br />
                理数据信息，绘制企业面像，实现政<br />
                策、商机、创新信息的适配精准推送<br /> */}
                1000+标签、全量数据库，为精准画像提供数据支撑、Al采集、分析、处理数据信息，绘制企业面像，实现政策、商机、创新信息的适配精准推送
              </p>
            </div>
          </div>
          <div className='div1'>
            <img src={mainIcon10} alt="" />
            <div>
              <p>SaaS服务 降本增效</p>
              <p>
                在线平台即开即用免安装，节<br />
                省安装、升级等成本<br />
                按需开通功能模块，避免不必<br />
                要的资源浪费
              </p>
            </div>
          </div>
          <div className='div1'>
            <img src={mainIcon11} alt="" />
            <div>
              <p>全量数据 更新推送</p>
              <p>
                涵盖千万级人才库、30万+共<br />
                享客户圈等有效资源<br />
                全链路追踪、抓取，数据实时<br />
                更新并推送
              </p>
            </div>
          </div>
          <div className='div1'>
            <img src={mainIcon12} alt="" />
            <div>
              <p>权限细分 管控结合</p>
              <p>
                设置多层级权限管控、精细划分<br />
                账号权限功能、数据按需配置，<br />
                支持企业数据信息合理流转
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div>
          <img src={logo2} alt="" />
          <p className='p1'>联系我们</p>
          <p className='p2'>商务电话：  13811270773</p>
          <p className='p2'>商务合作：  leadingins@163.com</p>
          <p className='p4'>版权所有 ©北京领军信息技术研究院有限公司 ｜<a target="_blank" href="https://beian.miit.gov.cn">京ICP备2020040813号</a></p>
        </div>
        <div>
        <img src={erWeiMa} alt="" />
        </div>
      </footer>
      <div className='ziXun'>
       
        <div className='ziXunDiv'>
          <img src={mainIcon13} alt="" />立即咨询
        </div>
          <img className='img1' src={erWeiMa} alt="" />
      </div>
    </div>
  )
}
